import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./FormStyle.css";

function LoginForm({ t }) {
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");

  return (
    <div className="Formulaire">
      <div className="form login">
        <div className="form-content">
          <header>{t("login_header")}</header>
          <form>
            <div className="field input-field">
              <input
                type="email"
                id="email"
                name="email"
                value={Email}
                placeholder={t("email_placeholder")}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="field input-field">
              <input
                type="password"
                id="password"
                name="password"
                value={Password}
                placeholder={t("password_placeholder")}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="field button-field">
              <button type="submit" className="btn">
                {t("login_button")}
              </button>
            </div>
          </form>

          <div className="form-link">
            <span>
              {t("no_account_text")}{" "}
              <Link to="/signup" className="link">
                {t("sign_up_link")}
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LoginForm;
