import React, { useEffect, useState } from "react";
import DescriptionSite from "../components/SiteComponents/DescriptionSite";
import { useParams } from "react-router-dom";
import "./main.css";
import "../components/SiteComponents/DescriptionSiteStyle.css";
import { Helmet } from "react-helmet";

const Site = ({ t, data }) => {
  const [item, setItem] = useState({});
  let { title, site } = useParams();

  // Normalize 'site' and 'title' parameters
  site = site.trim().toLowerCase();
  title = title.trim().toLowerCase();

  useEffect(() => {
    if (data.length > 0) {
      // Find the main site by matching title
      let mainSite = data.find(
        (siteData) => siteData.link.toLowerCase() === title
      );

      if (mainSite) {
        // Flatten the nested `sites` array into a single array
        const flattenedSites = Object.values(mainSite.sites).flat();
        // Find the specific site in the flattened array
        const tmp = flattenedSites.find((s) => s.link.toLowerCase() === site);
        if (!tmp) window.location.href = "/NotFound";
        else setItem(tmp);
      }
    }
  }, [data]);

  return (
    <div className="site">
      {item.title && (
        <Helmet>
          <title>{item.title} - Historiar</title>
          <meta name="description" content={item.description} />
        </Helmet>
      )}
      <div className="bkg">
        <div>
          <div className="container">
            <img className="bkg-img" src={item.image} alt="Background" />
          </div>
          <div className="content-site">
            <DescriptionSite t={t} data={item} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Site;
