import React from "react";
import "./ErrorStyles.css";
import model from "../../assets/historiar-model.png";
import { Typography } from "@mui/material";

const Error = ({ t }) => {
  return (
    <div className="bkg-error">
      <div className="container-error">
        <img className="bg-img" src={model} alt="background" />
      </div>

      <div className="content-error">
        <Typography
          variant="h1"
          gutterBottom
          sx={{ fontWeight: "bold", color: "#bf4f47" }}
        >
          {t("payment_error")}
        </Typography>
        <Typography variant="h3" gutterBottom sx={{ color: "white" }}>
          {t("payment_error_message")}
        </Typography>
        <br />
        <button
          type="button"
          className="btn"
          onClick={() => (window.location.href = "/")}
        >
          {t("return")}
        </button>
      </div>
    </div>
  );
};

export default Error;
