import axios from "axios";
import dataFR from "../assets/data/data_fr.json";
import dataEN from "../assets/data/data_en.json";
import dataAR from "../assets/data/data_ar.json";

const loadContent = async (language) => {
  /* const result = await axios.get(
    process.env.REACT_APP_API_PATH + `/content/${language}`
  );

  return result.data.sites; */

  if (language == "fr") return dataFR.sites;
  else if (language == "ar") return dataAR.sites;
  else return dataEN.sites;
};

export { loadContent };
