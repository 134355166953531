import CookieConsent from "react-cookie-consent";

const CookieConsentForm = () => {
  return (
    <CookieConsent
      location="bottom"
      cookieName="cookies"
      style={{ background: "#f9db1f", color: "black" }}
      buttonStyle={{
        color: "black",
        backgroundColor: "white",
      }}
    >
      This website uses cookies to enhance the user experience.{" "}
    </CookieConsent>
  );
};

export default CookieConsentForm;
