import React from "react";
import { MdOutlinePrivacyTip } from "react-icons/md";
import "./PolicyStyle.css";

function PrivacyComponentFR() {
  return (
    <div
      className="policy-body"
      style={{ direction: "ltr", textAlign: "left" }}
    >
      <div className="policy-content">
        <p className="updated-date">Dernière mise à jour : 10 octobre 2024</p>
        <header style={{ fontSize: "30px" }}>
          <MdOutlinePrivacyTip />
          &nbsp;&nbsp; Politique de Confidentialité
        </header>
        <br />
        <hr
          style={{ border: "none", height: "1px", backgroundColor: "#e8e8e8" }}
        />
        <br />
        <section className="section">
          <p>
            Cette politique de confidentialité décrit nos politiques et
            procédures concernant la collecte, l'utilisation et la divulgation
            de vos informations lorsque vous utilisez le service et vous informe
            de vos droits en matière de confidentialité et de la manière dont la
            loi vous protège.
          </p>
          <p>
            Nous utilisons vos données personnelles pour fournir et améliorer le
            service. En utilisant le service, vous acceptez la collecte et
            l'utilisation des informations conformément à cette politique de
            confidentialité. Cette politique de confidentialité a été créée à
            l'aide du
            <a
              href="https://www.freeprivacypolicy.com/free-privacy-policy-generator/"
              target="_blank"
              rel="noopener noreferrer"
            >
              générateur de politique de confidentialité gratuit
            </a>
            .
          </p>
        </section>

        <h1>Interprétation et Définitions</h1>

        <section className="section">
          <h2>Interprétation</h2>
          <p>
            Les mots dont la première lettre est en majuscule ont des
            significations définies dans les conditions suivantes. Ces
            définitions auront la même signification qu'elles apparaissent au
            singulier ou au pluriel.
          </p>
        </section>

        <section className="section">
          <h2>Définitions</h2>
          <p>Aux fins de la présente politique de confidentialité :</p>
          <ul>
            <li>
              <strong>Compte :</strong> un compte unique créé pour vous
              permettre d'accéder à notre service ou à certaines parties de
              celui-ci.
            </li>
            <li>
              <strong>Affilié :</strong> désigne une entité qui contrôle, est
              contrôlée par ou est sous contrôle commun avec une partie, où
              "contrôle" signifie la détention de 50% ou plus des actions, des
              intérêts ou des titres donnant droit de vote.
            </li>
            <li>
              <strong>Application :</strong> le programme logiciel fourni par la
              société téléchargé sur tout appareil électronique, nommé Demo.
            </li>
            <li>
              <strong>Entreprise :</strong> (désignée par "l'entreprise",
              "nous", "notre" ou "nos" dans le présent accord) fait référence à
              HISTORIAR, Bureau B1.1.2, Tunisie.
            </li>
            <li>
              <strong>Pays :</strong> fait référence à la Tunisie.
            </li>
            <li>
              <strong>Appareil :</strong> tout appareil permettant d'accéder au
              service tel qu'un ordinateur, un téléphone portable ou une
              tablette numérique.
            </li>
            <li>
              <strong>Données personnelles :</strong> désigne toute information
              se rapportant à une personne identifiée ou identifiable.
            </li>
            <li>
              <strong>Service :</strong> désigne l'application.
            </li>
            <li>
              <strong>Fournisseur de services :</strong> toute personne physique
              ou morale qui traite les données pour le compte de la société.
            </li>
            <li>
              <strong>Service de réseaux sociaux tiers :</strong> tout site web
              ou réseau social à partir duquel un utilisateur peut se connecter
              ou créer un compte pour utiliser le service.
            </li>
            <li>
              <strong>Données d'utilisation :</strong> fait référence aux
              données collectées automatiquement, générées par l'utilisation du
              service ou de l'infrastructure du service elle-même (par exemple,
              la durée de consultation d'une page).
            </li>
            <li>
              <strong>Vous :</strong> désigne la personne accédant au service ou
              l'utilisant, ou la société ou autre entité juridique au nom de
              laquelle cette personne accède ou utilise le service.
            </li>
          </ul>
        </section>

        <h1>Collecte et Utilisation de vos Données Personnelles</h1>

        <section className="section">
          <h2>Types de Données Collectées</h2>
          <h3>Données Personnelles</h3>
          <p>
            Lors de l'utilisation de notre service, nous pouvons vous demander
            de nous fournir certaines informations personnellement identifiables
            pouvant être utilisées pour vous contacter ou vous identifier. Les
            informations personnellement identifiables peuvent inclure, mais ne
            sont pas limitées à :
          </p>
          <ul>
            <li>Données d'utilisation</li>
          </ul>
        </section>

        <section className="section">
          <h3>Données d'utilisation</h3>
          <p>
            Les données d'utilisation sont collectées automatiquement lors de
            l'utilisation du service.
          </p>
          <p>
            Les données d'utilisation peuvent inclure des informations telles
            que l'adresse de protocole Internet (adresse IP) de votre appareil,
            le type de navigateur, la version du navigateur, les pages de notre
            service que vous visitez, l'heure et la date de votre visite, le
            temps passé sur ces pages, les identifiants uniques des appareils et
            d'autres données de diagnostic.
          </p>
        </section>

        <section className="section">
          <h3>Informations provenant des Services de Réseaux Sociaux Tiers</h3>
          <p>
            La société vous permet de créer un compte et de vous connecter pour
            utiliser le service via les services de réseaux sociaux tiers
            suivants :
          </p>
          <ul>
            <li>Google</li>
            <li>Facebook</li>
            <li>Twitter</li>
            <li>LinkedIn</li>
          </ul>
        </section>

        <section className="section">
          <h3>
            Informations Collectées lors de l'Utilisation de l'Application
          </h3>
          <p>
            Lors de l'utilisation de notre application, afin de fournir des
            fonctionnalités de notre application, nous pouvons, avec votre
            autorisation préalable, collecter :
          </p>
          <ul>
            <li>
              Des images et d'autres informations à partir de l'appareil photo
              et de la bibliothèque de photos de votre appareil.
            </li>
          </ul>
          <p>
            Nous utilisons ces informations pour fournir et améliorer nos
            services, et pour les personnaliser.
          </p>
        </section>
      </div>
    </div>
  );
}

export default PrivacyComponentFR;
