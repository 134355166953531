// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parallax-container {
  position: relative; /* Allow z-index to work */
  z-index: 0; /* Set a lower z-index */
}

.slider-accueil {
  padding-top: 8em;
  height: 30em;
}

.title-accueil {
  margin: 0 3vw; /* Simplified margin shorthand */
  color: white;
  font-weight: bold;
}

.span-accueil {
  font-size: 0.7em;
  text-decoration: underline;
  color: #f7e56d;
}

@media screen and (min-width: 700px) and (max-width: 1500px) {
  .slider-accueil {
    height: 30em; /* Can be adjusted if necessary */
  }
}

@media screen and (max-width: 700px) {
  .slider-accueil {
    height: 25em;
  }

  .h2-accueil {
    margin: 0 8vw; /* Simplified margin shorthand */
  }

  .span-accueil {
    font-size: 0.5em;
    display: block;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/AccueilComponents/ParallaxAccueilStyle.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB,EAAE,0BAA0B;EAC9C,UAAU,EAAE,wBAAwB;AACtC;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa,EAAE,gCAAgC;EAC/C,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;EAC1B,cAAc;AAChB;;AAEA;EACE;IACE,YAAY,EAAE,iCAAiC;EACjD;AACF;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,aAAa,EAAE,gCAAgC;EACjD;;EAEA;IACE,gBAAgB;IAChB,cAAc;EAChB;AACF","sourcesContent":[".parallax-container {\n  position: relative; /* Allow z-index to work */\n  z-index: 0; /* Set a lower z-index */\n}\n\n.slider-accueil {\n  padding-top: 8em;\n  height: 30em;\n}\n\n.title-accueil {\n  margin: 0 3vw; /* Simplified margin shorthand */\n  color: white;\n  font-weight: bold;\n}\n\n.span-accueil {\n  font-size: 0.7em;\n  text-decoration: underline;\n  color: #f7e56d;\n}\n\n@media screen and (min-width: 700px) and (max-width: 1500px) {\n  .slider-accueil {\n    height: 30em; /* Can be adjusted if necessary */\n  }\n}\n\n@media screen and (max-width: 700px) {\n  .slider-accueil {\n    height: 25em;\n  }\n\n  .h2-accueil {\n    margin: 0 8vw; /* Simplified margin shorthand */\n  }\n\n  .span-accueil {\n    font-size: 0.5em;\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
