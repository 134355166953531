import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DescriptionContenu from "../components/ContenuComponents/DescriptionContenu";
import { useParams } from "react-router-dom";
import ProductImagesSlider from "../components/Common/Slider";
import "./main.css";
import "../components/ContenuComponents/DescriptionContenuStyle.css";

const Contenu = ({ t, data }) => {
  const { title } = useParams();
  const [item, setItem] = useState({});

  useEffect(() => {
    if (data.length > 0) {
      const tmp = data.find(
        (i) => i.link.toLowerCase() === title.toLowerCase()
      );

      if (!tmp) {
        window.location.href = "/NotFound";
      } else {
        setItem(tmp);
      }
    }
  }, [data, title]);

  return (
    <div
      className="container-contenu"
      style={{ direction: t("lang") === "ar" ? "rtl" : "ltr" }}
    >
      {item.title && (
        <Helmet>
          <title>{item.title} - Historiar</title>
          <meta name="description" content={item.description} />
        </Helmet>
      )}

      <div className="bkg">
        {/* Background image container */}
        <div className="container">
          <img className="bkg-img" src={item.image} alt="Background" />
        </div>

        {/* Content container */}
        <div className="content-contenu">
          <DescriptionContenu t={t} data={item} />
          <br />
          {/* Display site information if available */}
          {item.sites &&
            Object.entries(item.sites).map(([era, sites], index) => (
              <div key={index}>
                <h2 className="sub-title">{era}</h2>
                <ProductImagesSlider data={sites} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Contenu;
