import React from "react";
import ReactCompareImage from "react-compare-image";
import CircularProgress from "@mui/material/CircularProgress";

export default function Comparison({ t, before, after }) {
  return (
    <ReactCompareImage
      leftImage={before}
      rightImage={after}
      leftImageLabel={t("then")}
      rightImageLabel={t("now")}
      leftImageAlt={t("then")}
      rightImageAlt={t("now")}
      skeleton={<CircularProgress />}
      aspectRatio="wider"
    />
  );
}
