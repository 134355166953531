import React from "react";
import { Helmet } from "react-helmet";
import "./main.css";
import PolicyComponentEN from "../components/PolicyComponents/PolicyComponentEN";
import PolicyComponentAR from "../components/PolicyComponents/PolicyComponentAR";
import PolicyComponentFR from "../components/PolicyComponents/PolicyComponentFR";

function PrivacyPolicy({ t }) {
  return (
    <div className="bg-img-policy">
      <Helmet>
        <title>Privacy policy - Historiar</title>
      </Helmet>
      {t("lang") === "en" ? (
        <PolicyComponentEN />
      ) : t("lang") === "ar" ? (
        <PolicyComponentAR />
      ) : (
        <PolicyComponentFR />
      )}
    </div>
  );
}
export default PrivacyPolicy;
