// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-selector {
  position: relative;
  display: inline-block;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background: none;
  border: none;
  cursor: pointer;
}

.flag-icon {
  width: 30px;
  height: 20px;
  border-radius: 4px;
}

.dropdown-menu {
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  top: 100%;
  left: -5px;
  max-width: 50px;
  border-radius: 4px;
  overflow: hidden;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.dropdown-item:hover {
  background: #f0f0f0;
}

.dropdown-item img {
  width: 30px;
  height: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Common/LanguageSwitcher.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,oCAAoC;EACpC,sBAAsB;EACtB,wCAAwC;EACxC,aAAa;EACb,SAAS;EACT,UAAU;EACV,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".language-selector {\n  position: relative;\n  display: inline-block;\n}\n\n.dropdown {\n  position: relative;\n  display: inline-block;\n}\n\n.dropdown-button {\n  background: none;\n  border: none;\n  cursor: pointer;\n}\n\n.flag-icon {\n  width: 30px;\n  height: 20px;\n  border-radius: 4px;\n}\n\n.dropdown-menu {\n  display: block;\n  position: absolute;\n  background: rgba(255, 255, 255, 0.5);\n  border: 1px solid #ddd;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);\n  z-index: 1000;\n  top: 100%;\n  left: -5px;\n  max-width: 50px;\n  border-radius: 4px;\n  overflow: hidden;\n}\n\n.dropdown-item {\n  display: flex;\n  align-items: center;\n  padding: 5px;\n  cursor: pointer;\n  transition: 0.3s;\n}\n\n.dropdown-item:hover {\n  background: #f0f0f0;\n}\n\n.dropdown-item img {\n  width: 30px;\n  height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
