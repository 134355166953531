export const contenu_en = {
  lang: "en",
  /* Home */
  home_header: "Take a trip, Unlike any other!",
  login: "Login",
  signup: "Signup",
  search: "Search",
  xr_experiences: "XR experiences",
  vr_ar_visits: "VR and AR visits",
  archeological_sites: "Archeological Sites",
  travel_back_in_time: "Travel back in time",
  download_our_app: "DOWNLOAD OUR APP",
  /* Footer */
  policy_and_terms: "Policy and terms",
  privacy_policy: "Privacy policy",
  social: "Social",
  get_in_touch: "Get in touch",
  /* Login */
  login_header: "Login",
  email_placeholder: "Email",
  password_placeholder: "Password",
  login_button: "Login",
  no_account_text: "Don't have an account? ",
  /* Signup */
  sign_up_link: "Sign up",
  signup_header: "Sign Up",
  firstname_placeholder: "Firstname",
  lastname_placeholder: "Lastname",
  phone_placeholder: "Phone number",
  sign_up_button: "Sign up",
  already_have_account: "Already have an account? ",
  /* Contenu */
  coming_soon: "Coming soon",
  /* Site */
  watch_now: "Visit",
  then: "Then",
  now: "Now",
  comparison: "Comparison",
  test_device: "Test Your Device",
  currency: "tnd",
  directions: "Directions",
  /* Payment */
  payment_error: "Payment Error",
  payment_error_message:
    "Unable to process the payment, please try again later.",
  return: "Return",
  /* 404 */
  page_not_found: "Page not found",
};
