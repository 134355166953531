import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs } from "swiper";
import { Link, useParams } from "react-router-dom";
import "./Slider.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";

const ProductImagesSlider = ({ data }) => {
  const { title } = useParams();

  return (
    <div className="slider-container">
      <Swiper
        spaceBetween={10}
        slidesPerView={2}
        breakpoints={{
          640: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        }}
        modules={[Navigation, Thumbs]}
        navigation
        thumbs={{ autoScrollOffset: 1 }}
      >
        {data
          ? data.map((item, index) => (
              <SwiperSlide key={index}>
                <Link
                  className="slide-content"
                  to={`/contenu/${title ? title + "/" : ""}${item.link}/`}
                >
                  <img
                    className="slide-image"
                    src={item.image}
                    alt={item.title}
                  />
                  <div className="label">
                    <div className="label-background"></div>
                    <div className="label-text">{item.title}</div>
                  </div>
                </Link>
              </SwiperSlide>
            ))
          : ""}
      </Swiper>
    </div>
  );
};

export default ProductImagesSlider;
