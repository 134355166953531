import React from "react";
import LoginForm from "../components/AuthComponents/LoginForm";
import "./main.css";
import { Helmet } from "react-helmet";

function Login({ t }) {
  return (
    <div className="bg-img">
      <Helmet>
        <title>{t("login_header")} - Historiar</title>
      </Helmet>
      <LoginForm t={t} />
    </div>
  );
}
export default Login;
