// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-Accueil {
  width: 100%;
  height: 100vh;
  position: relative;
}

.bg-img-Accueil {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  position: relative;
  filter: brightness(50%);
}

.bkg-Accueil {
  height: 100%;
  width: 100%;
}

.bkg-Accueil .content-Accueil {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.btn {
  width: 50%;
}

.content-Accueil .title-accueil {
  font-size: 4rem;
  padding: 0.6rem 0 2.5rem;
}

@media (max-width: 1200px) {
  .content-Accueil h1 {
    font-size: 4rem;
  }
}

@media (max-width: 768px) {
  .bkg-Accueil,
  .bg-img,
  .container-Accueil::after {
    height: 100vh;
  }
  .content-Accueil .title-accueil {
    font-size: 3.5rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/AccueilComponents/BgAccueilStyle.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,iBAAiB;EACjB,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,wBAAwB;AAC1B;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;;;IAGE,aAAa;EACf;EACA;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".container-Accueil {\n  width: 100%;\n  height: 100vh;\n  position: relative;\n}\n\n.bg-img-Accueil {\n  width: 100%;\n  height: 100vh;\n  background-size: cover;\n  background-position: center;\n  object-fit: cover;\n  position: relative;\n  filter: brightness(50%);\n}\n\n.bkg-Accueil {\n  height: 100%;\n  width: 100%;\n}\n\n.bkg-Accueil .content-Accueil {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  text-align: center;\n}\n\n.btn {\n  width: 50%;\n}\n\n.content-Accueil .title-accueil {\n  font-size: 4rem;\n  padding: 0.6rem 0 2.5rem;\n}\n\n@media (max-width: 1200px) {\n  .content-Accueil h1 {\n    font-size: 4rem;\n  }\n}\n\n@media (max-width: 768px) {\n  .bkg-Accueil,\n  .bg-img,\n  .container-Accueil::after {\n    height: 100vh;\n  }\n  .content-Accueil .title-accueil {\n    font-size: 3.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
