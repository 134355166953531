import React from "react";
import { Helmet } from "react-helmet";

const NotFound = ({ t }) => {
  const containerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
  };

  const contentStyle = {
    textAlign: "center",
  };

  return (
    <div style={containerStyle}>
      <Helmet>
        <title>{t("page_not_found")}</title>
      </Helmet>
      <div style={contentStyle}>
        <h1>{t("page_not_found")}</h1>
      </div>
    </div>
  );
};

export default NotFound;
