// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { contenu_en } from "./assets/contenu/contenu_en";
import { contenu_fr } from "./assets/contenu/contenu_fr";
import { contenu_ar } from "./assets/contenu/contenu_ar";

const resources = {
  en: { translation: contenu_en },
  fr: { translation: contenu_fr },
  ar: { translation: contenu_ar },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
