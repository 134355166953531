import React from "react";
import "./main.css";
import SignupForm from "../components/AuthComponents/SignupForm";
import { Helmet } from "react-helmet";

const Signup = ({ t }) => {
  return (
    <div className="bg-img">
      <Helmet>
        <title>{t("signup_header")} - Historiar</title>
      </Helmet>
      <SignupForm t={t} />
    </div>
  );
};

export default Signup;
