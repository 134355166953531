// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchBar {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
}

.searchIcon {
  margin-top: -1em;
  cursor: pointer;
}

.searchIcon {
  margin-top: -1em;
}

.autocomplete {
  width: 50vw; /* Adjust width for medium screens */
}

@media (max-width: 1200px) {
  .autocomplete {
    width: 60vw; /* Adjust width for medium screens */
  }
}

@media (max-width: 600px) {
  .autocomplete {
    width: 88vw; /* Adjust width for small screens */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/AccueilComponents/SearchBar.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW,EAAE,oCAAoC;AACnD;;AAEA;EACE;IACE,WAAW,EAAE,oCAAoC;EACnD;AACF;;AAEA;EACE;IACE,WAAW,EAAE,mCAAmC;EAClD;AACF","sourcesContent":[".searchBar {\n  background-color: rgb(255, 255, 255);\n  border-radius: 10px;\n}\n\n.searchIcon {\n  margin-top: -1em;\n  cursor: pointer;\n}\n\n.searchIcon {\n  margin-top: -1em;\n}\n\n.autocomplete {\n  width: 50vw; /* Adjust width for medium screens */\n}\n\n@media (max-width: 1200px) {\n  .autocomplete {\n    width: 60vw; /* Adjust width for medium screens */\n  }\n}\n\n@media (max-width: 600px) {\n  .autocomplete {\n    width: 88vw; /* Adjust width for small screens */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
