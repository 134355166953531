import React from "react";
import Error from "../components/PaymentComponents/Error";
import { Helmet } from "react-helmet";

const PaymentError = ({ t }) => {
  return (
    <>
      <Helmet>
        <title>{t("payment_error")}</title>
      </Helmet>
      <Error t={t} />
    </>
  );
};

export default PaymentError;
