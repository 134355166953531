import axios from "axios";

const orderService = async (site, language) => {
  const result = await axios.get(
    process.env.REACT_APP_API_PATH + `/order/${site}/${language}`
  );

  if (result.status === 200) window.open(result.data.result.link, "_blank");
};

export { orderService };
