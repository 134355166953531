import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./FormStyle.css";
import Grid from "@mui/material/Grid2";

const SignupForm = ({ t }) => {
  const [Firstname, setFirstname] = useState("");
  const [Lastname, setLastname] = useState("");
  const [Password, setPassword] = useState("");
  const [Email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");

  return (
    <div className="Formulaire">
      <div className="form login">
        <div className="form-content">
          <header style={{ marginBottom: "-1em" }}>{t("signup_header")}</header>
          <form>
            <Grid container spacing={1} rowSpacing={0}>
              <Grid size={6}>
                <div className="field input-field">
                  <input
                    type="text"
                    id="Firstname"
                    name="Firstname"
                    value={Firstname}
                    placeholder={t("firstname_placeholder")}
                    onChange={(e) => setFirstname(e.target.value)}
                  />
                </div>
              </Grid>
              <Grid size={6}>
                <div className="field input-field">
                  <input
                    type="text"
                    id="Lastname"
                    name="Lastname"
                    value={Lastname}
                    placeholder={t("lastname_placeholder")}
                    onChange={(e) => setLastname(e.target.value)}
                  />
                </div>
              </Grid>

              <div className="field button-field">
                <input
                  type="email"
                  id="Email"
                  name="Email"
                  value={Email}
                  placeholder={t("email_placeholder")}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="field button-field">
                <input
                  type="password"
                  id="Password"
                  name="Password"
                  value={Password}
                  placeholder={t("password_placeholder")}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="field button-field">
                <input
                  type="text"
                  id="Phone"
                  name="Phone"
                  value={Phone}
                  placeholder={t("phone_placeholder")}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="field button-field">
                <button type="submit" className="btn">
                  {t("sign_up_button")}
                </button>
              </div>
            </Grid>
          </form>

          <div className="form-link">
            <span>
              {t("already_have_account")}{" "}
              <Link to="/login" className="link">
                {t("login_button")}
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupForm;
