export const contenu_fr = {
  lang: "fr",
  /* Home */
  home_header: "Faites un voyage, pas comme les autres!",
  login: "Se connecter",
  signup: "Inscription",
  search: "Recherche",
  xr_experiences: "expériences xr",
  vr_ar_visits: "Visites en VR et AR",
  archeological_sites: "Sites archéologiques",
  travel_back_in_time: "Voyagez dans le temps",
  download_our_app: "TÉLÉCHARGEZ NOTRE APPLICATION",
  /* Footer */
  policy_and_terms: "Politique et conditions",
  privacy_policy: "Politique de confidentialité",
  social: "Réseaux sociaux",
  get_in_touch: "Contactez-nous",
  /* Login */
  login_header: "Connexion",
  email_placeholder: "Email",
  password_placeholder: "Mot de passe",
  login_button: "Se connecter",
  no_account_text: "Vous n'avez pas de compte? ",
  /* Signup */
  sign_up_link: "Inscription",
  signup_header: "S'inscrire",
  firstname_placeholder: "Prénom",
  lastname_placeholder: "Nom de famille",
  phone_placeholder: "Numéro de téléphone",
  sign_up_button: "S'inscrire",
  already_have_account: "Vous avez déjà un compte? ",
  /* Contenu */
  coming_soon: "À venir",
  /* Site */
  watch_now: "Visiter",
  then: "Avant",
  now: "Après",
  comparison: "Comparaison",
  test_device: "Testez votre appareil",
  currency: "tnd",
  directions: "itinéraires",
  /* Payment */
  payment_error: "Erreur de paiement",
  payment_error_message:
    "Impossible de traiter le paiement, veuillez réessayer plus tard.",
  return: "Retour",
  /* 404 */
  page_not_found: "Page non trouvée",
};
