import React from "react";
import phones from "../../assets/app-home.png";
import Grid from "@mui/material/Grid2";
import appStore from "../../assets/app-store.png";
import googlePlay from "../../assets/google-play.png";
import "./HomeComponentStyle.css";
import { Popover, Typography } from "@mui/material";

const HomeComponent = ({ t }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div className="_container">
      <Grid container className="content">
        <Grid size={{ xs: 12, sm: 12, md: 6 }} sx={{ padding: "1em" }}>
          <center>
            <div className="title-home">{t("travel_back_in_time")}</div>
            <br />
            <div className="sub-title-home">{t("download_our_app")}</div>
            <br />
            <br />
            <div className="app-icons">
              <img
                className="apple"
                src={appStore}
                alt="Download on the App Store"
                onClick={handleClick}
              />

              <img
                className="android"
                alt="Get it on Google Play"
                src={googlePlay}
                onClick={handleClick}
              />

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Typography sx={{ p: 2, color: "black" }}>
                  Coming soon.
                </Typography>
              </Popover>
            </div>
          </center>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 6 }} sx={{ marginTop: "1em" }}>
          <img className="phones" alt="Phones" src={phones} />
        </Grid>
      </Grid>
    </div>
  );
};

export default HomeComponent;
