import { Routes, Route } from "react-router-dom";
import Login from "./routes/Login";
import Signin from "./routes/Signup";
import Contenu from "./routes/Contenu";
import Accueil from "./routes/Accueil";
import PaymentError from "./routes/PaymentError";
import Site from "./routes/Site";
import NotFound from "./routes/NotFound";
import ScrollToTop from "./components/Common/ScrollToTop";
import PrivacyPolicy from "./routes/PrivacyPolicy";

function App({ t, data }) {
  return (
    <div>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Accueil t={t} data={data} />} />
        <Route path="/login" element={<Login t={t} />} />
        <Route path="/signup" element={<Signin t={t} />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy t={t} />} />
        <Route path="/contenu/:title" element={<Contenu t={t} data={data} />} />
        <Route
          path="/contenu/:title/:site"
          element={<Site t={t} data={data} />}
        />
        <Route path="/payment-error" element={<PaymentError t={t} />} />
        <Route path="*" element={<NotFound t={t} />} />
      </Routes>
    </div>
  );
}

export default App;
