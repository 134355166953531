export const contenu_ar = {
  lang: "ar",
  /* Home */
  home_header: "قم برحلة لا مثيل لها",
  login: "تسجيل الدخول",
  signup: "التسجيل",
  search: "بحث",
  xr_experiences: "تجارب الواقع الافتراضي",
  vr_ar_visits: "زيارات واقع افتراضي و معزز",
  archeological_sites: "المواقع الأثرية",
  travel_back_in_time: "سافر إلى الماضي",
  download_our_app: "قم بتحميل تطبيقنا",
  /* Footer */
  policy_and_terms: "السياسة والشروط",
  privacy_policy: "سياسة الخصوصية",
  social: "التواصل الاجتماعي",
  get_in_touch: "تواصل معنا",
  /* Login */
  login_header: "تسجيل الدخول",
  email_placeholder: "البريد الإلكتروني",
  password_placeholder: "كلمة المرور",
  login_button: "تسجيل الدخول",
  no_account_text: "ليس لديك حساب؟ ",
  /* Signup */
  sign_up_link: "التسجيل",
  signup_header: "إنشاء حساب",
  firstname_placeholder: "الاسم الأول",
  lastname_placeholder: "الاسم الأخير",
  phone_placeholder: "رقم الهاتف",
  sign_up_button: "تسجيل",
  already_have_account: "لديك حساب بالفعل؟ ",
  /* Contenu */
  coming_soon: "قريباً",
  /* Site */
  watch_now: "قم بالزيارة",
  then: "قبل ",
  now: "بعد",
  comparison: "مقارنة",
  test_device: "اختبر جهازك",
  currency: "دت",
  directions: "الاتجاهات",
  /* Payment */
  payment_error: "خطأ في الدفع",
  payment_error_message: "تعذر معالجة الدفع، يرجى المحاولة مرة أخرى لاحقاً",
  return: "العودة",
  /* 404 */
  page_not_found: "الصفحة غير موجودة",
};
