import React from "react";
import image from "../../assets/historiar-model.png";
import SearchBar from "./SearchBar";
import { Typography } from "@mui/material";
import "./BgAccueilStyle.css";

const BgAccueil = ({ t, data }) => {
  return (
    <div className="bkg-Accueil">
      <div className="container-Accueil">
        {/* <img className="bg-img-Accueil" src={image} alt="background" /> */}
        <video
          className="bg-img-Accueil"
          alt="background video"
          autoPlay
          muted
          loop
          onContextMenu={(e) => e.preventDefault()}
        >
          <source src="/videos/bg-home.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="content-Accueil">
        <Typography
          className="title-accueil"
          variant="h1"
          gutterBottom
          sx={{ fontWeight: "bold" }}
        >
          {t("home_header")}
        </Typography>
        <SearchBar t={t} data={data} />
      </div>
    </div>
  );
};

export default BgAccueil;
