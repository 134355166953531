import { useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import "./MapSiteStyle.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { t } from "i18next";

const MapSite = ({ coordinates, language }) => {
  const mapRef = useRef();
  const mapContainerRef = useRef();

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;
    if (mapboxgl.getRTLTextPluginStatus() === "unavailable")
      mapboxgl.setRTLTextPlugin(
        "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.3.0/mapbox-gl-rtl-text.js",
        null,
        true
      );

    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: coordinates
        ? [coordinates[0], coordinates[1]]
        : [9.555505, 34.689183],
      zoom: coordinates ? 15 : 4,
      language: language,
      interactive: false,
    });

    if (coordinates) {
      new mapboxgl.Marker()
        .setLngLat([coordinates[0], coordinates[1]])
        .addTo(mapRef.current);
    }

    return () => {
      mapRef.current.remove();
    };
  }, [coordinates, language]);

  // Function to open Google Maps with directions
  const openGoogleMaps = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        if (latitude && longitude) {
          const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${coordinates[1]},${coordinates[0]}`;
          window.open(googleMapsUrl, "_blank");
        } else {
          alert("Please allow location access to get directions.");
        }
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  return (
    <div>
      <div className="map-container" ref={mapContainerRef} />
      {coordinates && (
        <center>
          <button className="btn-light" onClick={openGoogleMaps}>
            {t("directions")}
          </button>
        </center>
      )}
    </div>
  );
};

export default MapSite;
