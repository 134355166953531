import React from "react";
import { MdOutlinePrivacyTip } from "react-icons/md";
import "./PolicyStyle.css";

function PolicyComponentEN() {
  return (
    <div className="policy-body">
      <div className="policy-content">
        <p className="updated-date">Last updated: October 10, 2024</p>
        <header style={{ fontSize: "30px" }}>
          <MdOutlinePrivacyTip />
          &nbsp;&nbsp; Privacy policy
        </header>
        <br />
        <hr
          style={{ border: "none", height: "1px", backgroundColor: "#e8e8e8" }}
        />
        <br />
        <section className="section">
          <p>
            This Privacy Policy describes Our policies and procedures on the
            collection, use, and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </p>
          <p>
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy. This Privacy
            Policy has been created with the help of the{" "}
            <a
              href="https://www.freeprivacypolicy.com/free-privacy-policy-generator/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Free Privacy Policy Generator
            </a>
            .
          </p>
        </section>

        <h1>Interpretation and Definitions</h1>

        <section className="section">
          <h2>Interpretation</h2>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
        </section>

        <section className="section">
          <h2>Definitions</h2>
          <p>For the purposes of this Privacy Policy:</p>
          <ul>
            <li>
              <strong>Account:</strong> A unique account created for You to
              access our Service or parts of our Service.
            </li>
            <li>
              <strong>Affiliate:</strong> An entity that controls, is controlled
              by, or is under common control with a party, where "control" means
              ownership of 50% or more of the shares, equity interest, or other
              securities entitled to vote for election of directors or other
              managing authority.
            </li>
            <li>
              <strong>Application:</strong> The software program provided by the
              Company downloaded by You on any electronic device, named Demo.
            </li>
            <li>
              <strong>Company:</strong> (referred to as either "the Company",
              "We", "Us" or "Our" in this Agreement) refers to HISTORIAR, Cercle
              des bureaux, Bloc B, Bureau n°: B1.1.2 Centre Urbain nord – Tunis.
            </li>
            <li>
              <strong>Country:</strong> Refers to: Tunisia.
            </li>
            <li>
              <strong>Device:</strong> Any device that can access the Service
              such as a computer, a cellphone, or a digital tablet.
            </li>
            <li>
              <strong>Personal Data:</strong> Any information that relates to an
              identified or identifiable individual.
            </li>
            <li>
              <strong>Service:</strong> Refers to the Application.
            </li>
            <li>
              <strong>Service Provider:</strong> Any natural or legal person who
              processes the data on behalf of the Company. It refers to
              third-party companies or individuals employed by the Company to
              facilitate the Service.
            </li>
            <li>
              <strong>Third-party Social Media Service:</strong> Any website or
              social network website through which a User can log in or create
              an account to use the Service.
            </li>
            <li>
              <strong>Usage Data:</strong> Data collected automatically, either
              generated by the use of the Service or from the Service
              infrastructure itself (for example, the duration of a page visit).
            </li>
            <li>
              <strong>You:</strong> The individual accessing or using the
              Service, or the company or other legal entity on behalf of which
              such individual is accessing or using the Service, as applicable.
            </li>
          </ul>
        </section>

        <h1>Collecting and Using Your Personal Data</h1>

        <section className="section">
          <h2>Types of Data Collected</h2>
          <h3>Personal Data</h3>
          <p>
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>
          <ul>
            <li>Usage Data</li>
          </ul>
        </section>

        <section className="section">
          <h3>Usage Data</h3>
          <p>Usage Data is collected automatically when using the Service.</p>
          <p>
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g., IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers, and
            other diagnostic data.
          </p>
        </section>

        <section className="section">
          <h3>Information from Third-Party Social Media Services</h3>
          <p>
            The Company allows You to create an account and log in to use the
            Service through the following Third-party Social Media Services:
          </p>
          <ul>
            <li>Google</li>
            <li>Facebook</li>
            <li>Twitter</li>
            <li>LinkedIn</li>
          </ul>
        </section>

        <section className="section">
          <h3>Information Collected while Using the Application</h3>
          <p>
            While using Our Application, in order to provide features of Our
            Application, We may collect, with Your prior permission:
          </p>
          <ul>
            <li>
              Pictures and other information from your Device's camera and photo
              library
            </li>
          </ul>
          <p>
            We use this information to provide features of Our Service, to
            improve and customize Our Service.
          </p>
        </section>
      </div>
    </div>
  );
}

export default PolicyComponentEN;
