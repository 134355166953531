import React, { useState, useEffect } from "react";
import "./NavbarStyle.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import LanguageSwitcher from "./LanguageSwitcher";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import RedeemIcon from "@mui/icons-material/Redeem";
import LoginIcon from "@mui/icons-material/Login";

const Navbar = ({ t }) => {
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const login = () => {
    setIsMenuOpen(false);
    navigate("/login");
  };

  return (
    <nav className={`navbar ${isScrolled ? "scrolled" : ""}`}>
      <div className="navbar-container">
        <div className="logo">
          <Link to="/" className="link-logo">
            <img
              className="Logo-image"
              src={logo}
              alt="Logo Historiar"
              width="90"
            />
          </Link>
        </div>
        <div className={`menu ${isMenuOpen ? "open" : ""}`}>
          {/* <div className="toggle-menu">
            <button
              className="menu-button-open"
              onClick={toggleMenu}
              style={{ display: isMenuOpen ? "none" : "block" }}
            >
              <MenuIcon />
            </button>

            <button
              className="menu-button-close"
              onClick={toggleMenu}
              style={{ display: isMenuOpen ? "block" : "none" }}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="menu-items">
            <button className="btn-light" id="btn-navbar" onClick={login}>
              <RedeemIcon />
              &nbsp;Redeem
            </button>
            <button className="btn-light" id="btn-navbar" onClick={login}>
              <ShoppingCartIcon />
              &nbsp;Cart
            </button>
            <button className="btn-light" id="btn-navbar" onClick={login}>
              <LoginIcon />
              &nbsp;
              {t("login")}
            </button>
          </div> */}

          <div style={{ marginLeft: "1em" }}>
            <LanguageSwitcher />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
