import React from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import "./SearchBar.css";

function SearchBar({ t, data }) {
  const navigate = useNavigate();

  // Transform the data to flatten the structure and prepare it for grouping
  const transformedData = data.flatMap((site) =>
    Object.entries(site.sites).flatMap(([era, sites]) =>
      sites.map((place) => ({
        group: site.title, // Use site title as the group
        siteLink: site.link,
        title: place.title, // Use place title as the option
        subsiteLink: place.link, // Link for navigation
      }))
    )
  );

  const handleInputChange = (event, value) => {
    if (value && value.siteLink) {
      navigate(`/contenu/${value.siteLink}/${value.subsiteLink}/`);
    }
  };

  return (
    <Autocomplete
      freeSolo
      onChange={handleInputChange}
      className="autocomplete"
      id="free-solo-demo"
      options={transformedData}
      groupBy={(option) => option.group} // Group options by the group key
      getOptionLabel={(option) => option.title} // Use title as the label
      renderInput={(params) => (
        <TextField
          {...params}
          className="searchBar"
          variant="filled"
          label={t("search") + "..."}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
                <InputAdornment position="end" className="searchIcon">
                  <SearchIcon />
                </InputAdornment>
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default SearchBar;
