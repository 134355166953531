import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./LanguageSwitcher.css";
import { useCookies } from "react-cookie";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["language"]);
  const [selectedLanguage, setSelectedLanguage] = useState(
    cookies["language"] ? cookies["language"] : "en"
  );

  const handleLanguageChange = (lang) => {
    setSelectedLanguage(lang);
    setIsOpen(false);
    i18n.changeLanguage(lang);
    removeCookie("language");
    setCookie("language", lang, {
      path: "/",
      expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
    });
    window.location.reload();
  };

  useEffect(() => {
    if (cookies["language"]) i18n.changeLanguage(cookies["language"]);
  }, [cookies["language"]]);

  return (
    <div className="language-selector">
      <div className="dropdown">
        <button className="dropdown-button" onClick={() => setIsOpen(!isOpen)}>
          <img
            src={
              selectedLanguage == "ar"
                ? "/flags/tn.png"
                : selectedLanguage == "en"
                ? "/flags/en.png"
                : "/flags/fr.png"
            }
            alt={selectedLanguage}
            className="flag-icon"
          />
        </button>
        {isOpen && (
          <div className="dropdown-menu">
            <div
              className="dropdown-item"
              onClick={() => handleLanguageChange("en")}
            >
              <img src="/flags/en.png" alt="English" className="flag-icon" />
            </div>
            <div
              className="dropdown-item"
              onClick={() => handleLanguageChange("fr")}
            >
              <img src="/flags/fr.png" alt="French" className="flag-icon" />
            </div>
            <div
              className="dropdown-item"
              onClick={() => handleLanguageChange("ar")}
            >
              <img src="/flags/tn.png" alt="Arabic" className="flag-icon" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LanguageSwitcher;
