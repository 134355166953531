import React from "react";
import { MdOutlinePrivacyTip } from "react-icons/md";
import "./PolicyStyle.css";

function PrivacyComponentAR() {
  return (
    <div
      className="policy-body"
      style={{ direction: "rtl", textAlign: "right" }}
    >
      <div className="policy-content">
        <p className="updated-date">آخر تحديث: 10 أكتوبر 2024</p>
        <header style={{ fontSize: "30px" }}>
          <MdOutlinePrivacyTip />
          &nbsp;&nbsp; سياسة الخصوصية
        </header>
        <br />
        <hr
          style={{ border: "none", height: "1px", backgroundColor: "#e8e8e8" }}
        />
        <br />
        <section className="section">
          <p>
            تصف سياسة الخصوصية هذه سياساتنا وإجراءاتنا المتعلقة بجمع معلوماتك
            واستخدامها والإفصاح عنها عند استخدامك للخدمة وتخبرك بحقوقك المتعلقة
            بالخصوصية وكيف يحميك القانون.
          </p>
          <p>
            نستخدم بياناتك الشخصية لتوفير الخدمة وتحسينها. باستخدامك للخدمة،
            فإنك توافق على جمع المعلومات واستخدامها وفقًا لسياسة الخصوصية هذه.
            تم إنشاء سياسة الخصوصية هذه بمساعدة
            <a
              href="https://www.freeprivacypolicy.com/free-privacy-policy-generator/"
              target="_blank"
              rel="noopener noreferrer"
            >
              مُنشئ سياسة الخصوصية المجاني
            </a>
            .
          </p>
        </section>

        <h1>التفسير والتعريفات</h1>

        <section className="section">
          <h2>التفسير</h2>
          <p>
            الكلمات التي يتم كتابة الحرف الأول منها بحرف كبير تحمل المعاني
            المعرفة في ظل الشروط التالية. وتظل هذه التعريفات لها نفس المعنى سواء
            ظهرت في صيغة المفرد أو الجمع.
          </p>
        </section>

        <section className="section">
          <h2>التعريفات</h2>
          <p>لأغراض سياسة الخصوصية هذه:</p>
          <ul>
            <li>
              <strong>الحساب:</strong> حساب فريد يتم إنشاؤه لك للوصول إلى خدمتنا
              أو أجزاء منها.
            </li>
            <li>
              <strong>التابع:</strong> كيان يسيطر عليه أو يكون تحت سيطرة نفس
              السلطة مع أحد الأطراف، حيث تعني "السيطرة" ملكية 50% أو أكثر من
              الأسهم أو المصالح أو الأوراق المالية التي تمنح حق التصويت.
            </li>
            <li>
              <strong>التطبيق:</strong> البرنامج المقدم من الشركة والذي تم
              تحميله على أي جهاز إلكتروني، ويُدعى Demo.
            </li>
            <li>
              <strong>الشركة:</strong> (يُشار إليها إما بـ "الشركة" أو "نحن" أو
              "لنا" أو "خاصتنا" في هذه الاتفاقية) تشير إلى HISTORIAR، مكتب
              B1.1.2، تونس.
            </li>
            <li>
              <strong>البلد:</strong> يشير إلى تونس.
            </li>
            <li>
              <strong>الجهاز:</strong> أي جهاز يمكنه الوصول إلى الخدمة مثل
              الكمبيوتر أو الهاتف المحمول أو الجهاز اللوحي.
            </li>
            <li>
              <strong>البيانات الشخصية:</strong> أي معلومات تتعلق بشخص معرف أو
              يمكن تحديده.
            </li>
            <li>
              <strong>الخدمة:</strong> تشير إلى التطبيق.
            </li>
            <li>
              <strong>مزود الخدمة:</strong> أي شخص طبيعي أو اعتباري يعالج
              البيانات نيابةً عن الشركة.
            </li>
            <li>
              <strong>خدمة وسائل التواصل الاجتماعي التابعة لجهة خارجية:</strong>
              أي موقع ويب أو شبكة اجتماعية يمكن من خلالها للمستخدم تسجيل الدخول
              أو إنشاء حساب لاستخدام الخدمة.
            </li>
            <li>
              <strong>بيانات الاستخدام:</strong> البيانات التي يتم جمعها
              تلقائيًا سواء تم إنشاؤها باستخدام الخدمة أو من البنية التحتية
              للخدمة نفسها (مثل مدة زيارة الصفحة).
            </li>
            <li>
              <strong>أنت:</strong> الفرد الذي يصل إلى الخدمة أو يستخدمها، أو
              الشركة أو الكيان القانوني الذي يمثله هذا الفرد.
            </li>
          </ul>
        </section>

        <h1>جمع واستخدام بياناتك الشخصية</h1>

        <section className="section">
          <h2>أنواع البيانات التي يتم جمعها</h2>
          <h3>البيانات الشخصية</h3>
          <p>
            أثناء استخدامك لخدمتنا، قد نطلب منك تقديم معلومات شخصية يمكن أن
            تُستخدم للاتصال بك أو التعرف عليك. قد تشمل هذه المعلومات الشخصية،
            ولكن ليست محدودة بـ:
          </p>
          <ul>
            <li>بيانات الاستخدام</li>
          </ul>
        </section>

        <section className="section">
          <h3>بيانات الاستخدام</h3>
          <p>يتم جمع بيانات الاستخدام تلقائيًا أثناء استخدام الخدمة.</p>
          <p>
            قد تشمل بيانات الاستخدام معلومات مثل عنوان بروتوكول الإنترنت الخاص
            بجهازك (مثل عنوان IP)، نوع المتصفح، إصدار المتصفح، الصفحات التي
            تزورها على خدمتنا، وقت وتاريخ زيارتك، الوقت الذي قضيته على تلك
            الصفحات، معرفات الجهاز الفريدة، وبيانات التشخيص الأخرى.
          </p>
        </section>

        <section className="section">
          <h3>
            المعلومات من خدمات وسائل التواصل الاجتماعي التابعة لجهة خارجية
          </h3>
          <p>
            تسمح لك الشركة بإنشاء حساب وتسجيل الدخول لاستخدام الخدمة من خلال
            خدمات وسائل التواصل الاجتماعي التابعة لجهة خارجية مثل:
          </p>
          <ul>
            <li>Google</li>
            <li>Facebook</li>
            <li>Twitter</li>
            <li>LinkedIn</li>
          </ul>
        </section>

        <section className="section">
          <h3>المعلومات التي يتم جمعها أثناء استخدام التطبيق</h3>
          <p>
            أثناء استخدام تطبيقنا، ولتوفير ميزات تطبيقنا، قد نجمع، بعد إذنك
            المسبق:
          </p>
          <ul>
            <li>الصور والمعلومات الأخرى من كاميرا جهازك ومكتبة الصور.</li>
          </ul>
          <p>نستخدم هذه المعلومات لتوفير ميزات خدمتنا وتحسينها وتخصيصها.</p>
        </section>
      </div>
    </div>
  );
}

export default PrivacyComponentAR;
