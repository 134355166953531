import React, { useState, useEffect } from "react";
import "./DescriptionSiteStyle.css";
import Comparison from "./Comparison";
import Grid from "@mui/material/Grid2";
import { orderService } from "../../services/payment";
import { Container } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import MapSite from "./MapSite";

const DescriptionSite = ({ t, data }) => {
  const [imagesLoaded, setImagesLoaded] = useState(null);

  useEffect(() => {
    // Helper function to check if an image path is valid
    const checkImage = (src) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
      });
    };

    // Check both image paths
    const loadImages = async () => {
      const beforeExists = await checkImage(data.before);
      const afterExists = await checkImage(data.after);

      // Only set imagesLoaded to true if both images exist
      if (beforeExists && afterExists) {
        setImagesLoaded(true);
      } else setImagesLoaded(false);
    };

    loadImages();
  }, [data, imagesLoaded]);

  const order = async () => {
    await orderService(data.link, t("lang"));
  };

  const test = async () => {
    window.open(`https://vworld.historiar.io/browse/test`, "_blank");
  };

  return (
    <Container
      maxWidth="lg"
      style={{ direction: t("lang") === "ar" ? "rtl" : "ltr" }}
    >
      <div>
        <h1 className="title-site">
          {data.title}{" "}
          {data.comingSoon && (
            <span className="coming-soon">(coming soon)</span>
          )}
        </h1>
      </div>
      <Grid container spacing={4}>
        <Grid size={{ xs: 12, md: 6 }}>
          <p className="description-site">{data.description}</p>
          <br />

          {!data.comingSoon && (
            <div className="btn-group">
              <button className="btn" id="btn-site" onClick={order}>
                {`${t("watch_now")} (${data.price}${t("currency")})`}
              </button>
              <button className="btn" id="btn-site" onClick={test}>
                {t("test_device")}
              </button>
            </div>
          )}
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          {imagesLoaded == null && (
            <center>
              <CircularProgress />
            </center>
          )}

          {imagesLoaded == true && (
            <div>
              <Comparison t={t} before={data.before} after={data.after} />
              <br />
              <MapSite coordinates={data.coordinates} language={t("lang")} />
            </div>
          )}

          {imagesLoaded == false && (
            <MapSite coordinates={data.coordinates} language={t("lang")} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default DescriptionSite;
