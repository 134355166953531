import React from "react";
import BgAccueil from "../components/AccueilComponents/BgAccueil";
import HomeComponent from "../components/AccueilComponents/HomeComponent";
import { ParallaxProvider } from "react-scroll-parallax";
import { Helmet } from "react-helmet";
import "./main.css";
import ParallaxAccueil from "../components/AccueilComponents/ParallaxAccueil";

const Accueil = ({ t, data }) => {
  return (
    <ParallaxProvider>
      <Helmet>
        <title>Historiar</title>
      </Helmet>
      <div className="top">
        <BgAccueil t={t} data={data} />
        <div className="line" />
      </div>
      <br />
      <ParallaxAccueil t={t} data={data} />
      <div className="top">
        <div className="line" />
        <HomeComponent t={t} />
      </div>
    </ParallaxProvider>
  );
};

export default Accueil;
