// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif;
}
body {
  background: rgb(10, 10, 10);
}
h1,
h4,
p,
a {
  color: #fff;
  text-decoration: none;
}
ul {
  list-style-type: none;
}

.btn {
  padding: 8px 28px;
  font-size: 1rem;
  text-transform: uppercase;
  background: #f9db1f;
  color: #222;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}

.btn-light {
  padding: 8px 28px;
  font-size: 1rem;
  text-transform: uppercase;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}

.btn:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  transition: 0.3s;
}
.btn-light:hover {
  background: transparent;
  color: #f9db1f;
  transition: 0.3s;
  border: 1px solid #f9db1f;
}

.line {
  height: 8px;
  background: #3c3d37;
  justify-content: center;
  position: relative;
}

.top {
  position: relative;
  z-index: 1;
}
`, "",{"version":3,"sources":["webpack://./src/routes/main.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,iCAAiC;AACnC;AACA;EACE,2BAA2B;AAC7B;AACA;;;;EAIE,WAAW;EACX,qBAAqB;AACvB;AACA;EACE,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,yBAAyB;EACzB,mBAAmB;EACnB,WAAW;EACX,sBAAsB;EACtB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,yBAAyB;EACzB,uBAAuB;EACvB,WAAW;EACX,sBAAsB;EACtB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,oCAAoC;EACpC,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,uBAAuB;EACvB,cAAc;EACd,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap\");\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: \"Outfit\", sans-serif;\n}\nbody {\n  background: rgb(10, 10, 10);\n}\nh1,\nh4,\np,\na {\n  color: #fff;\n  text-decoration: none;\n}\nul {\n  list-style-type: none;\n}\n\n.btn {\n  padding: 8px 28px;\n  font-size: 1rem;\n  text-transform: uppercase;\n  background: #f9db1f;\n  color: #222;\n  border: 1px solid #fff;\n  font-weight: 600;\n  cursor: pointer;\n}\n\n.btn-light {\n  padding: 8px 28px;\n  font-size: 1rem;\n  text-transform: uppercase;\n  background: transparent;\n  color: #fff;\n  border: 1px solid #fff;\n  font-weight: 600;\n  cursor: pointer;\n}\n\n.btn:hover {\n  background: rgba(255, 255, 255, 0.2);\n  color: #fff;\n  transition: 0.3s;\n}\n.btn-light:hover {\n  background: transparent;\n  color: #f9db1f;\n  transition: 0.3s;\n  border: 1px solid #f9db1f;\n}\n\n.line {\n  height: 8px;\n  background: #3c3d37;\n  justify-content: center;\n  position: relative;\n}\n\n.top {\n  position: relative;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
